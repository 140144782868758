/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum LeaseStatus {
    DRAFT = 'draft',
    LEASED = 'leased',
    HOLDOVER = 'holdover',
    EXPIRED = 'expired',
    SURRENDERED = 'surrendered',
    TRANSFERRED = 'transferred',
    UNDER_NEGOTIATION = 'underNegotiation',
    NOT_SIGNED = 'notSigned',
    OWNED = 'owned',
    OPTION_EXERCISED = 'optionExercised',
    OPTION_EXPIRED = 'optionExpired',
    OPTION_EXERCISED_FUTURE_DATED = 'optionExercisedFutureDated',
    LEASE_EXTENDED = 'leaseExtended',
    CLOSED = 'closed',
    DELETED = 'deleted',
}
